$fontFamily: "Open Sans", sans-serif;
$colorBlack: #000000;
$colorWhite: #ffffff;
$colorGrey: #888888;
$colorBlue: #67a1c6;
$colorGreen: #6dbec6;
$colorYellow: #f0b24f;
$colorPurple: #af81cc;
$colorPartire: #F2F2F2;
$colorRed: #e46a5d;
$colorGrey: #5f7884;
$temaBlue: rgba(103, 161, 198, 0.5);
$temaGreen: rgba(109,190,198, 0.5);
$temaYellow: rgba(204,178,79, 0.5);
$temaPurple: rgba(175,129,204, 0.5);

