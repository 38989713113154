@mixin form-group {
  box-shadow: 0px 0px 10px rgb(218, 218, 218);
  border-radius: 10px;
  width: 100%;

  label {
    font-family: $fontFamily;
    font-size: 16px;
    color: $colorBlack;
    text-transform: uppercase;
    margin: 0;
  }
  .form-control {
    background: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: $fontFamily !important;
    font-size: 14px !important;
    color: $colorBlack !important;
    padding: 0;

    &::placeholder {
      font-family: $fontFamily;
      font-size: 11px;
      color: $colorGrey;
    }
  }
  .btn {
    border: 0;
    border-radius: 0;
    background: transparent;
    color: $colorBlack;
    font-size: 10px;
    &.active,
    &:hover,
    &:focus {
      background-color: #e3e3e3 !important;
      border-radius: 8px !important;
      color: $colorBlack !important;
      border: 0 !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
    &.active {
      background-color: #cde7ec !important;
    }
  }
  textarea.form-control {
    height: 100px;
    resize: none;
    &::placeholder {
      transform: translate(0, 70px);
    }
  }
  .number {
    background: #e3e3e3;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    color: $colorBlack !important;
    box-shadow: 0px 0px 10px #aaa;
  }
}

@mixin modal {
  .button-modal,
  .button-modal.disabled {
    border-radius: 30px;
    background-color: #008ed6;
    color: #fff;
    font-weight: bold;
    border: 0;
    outline: none;
    opacity: 1;
    &:hover {
      background-color: #000;
    }
    &.cancel {
      background-color: #577884;
      &:hover {
        background-color: #000;
      }
    }
  }

  .form-control {
    background: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: $fontFamily;
    font-size: 20px;
    color: $colorBlack !important;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
    &::placeholder {
      font-family: $fontFamily;
      font-size: 11px;
      color: $colorGrey;
    }
  }

  .title {
    font-size: 26px;
    color: #000;
  }

  .description {
    font-size: 18px;
    color: #000;
  }

  .action {
    font-size: 14px;
    color: #888;
  }
}

@mixin listify {
  $listify: (
    "ul": (
      paddingLeft: 1rem,
      "li": (
        gap: 0.5rem,
        strikeColor: #000,
        strikeWidth: 1px,
        v-strikeGap: 0.5rem,
        v-strikeLength: 0.5rem,
      ),
    ),
  );
  ::-webkit-scrollbar {
    display: none;
  }
  ul.listify {
    $mapScope: map-get($listify, "ul");
    list-style: none;
    margin: 0;
    padding-left: map-get($mapScope, paddingLeft);
    > li {
      $mapScope: map-get($mapScope, "li");
      line-height: 1;
      padding-bottom: map-get($mapScope, gap);
      padding-left: calc(#{map-get($mapScope, v-strikeLength)} + #{map-get($mapScope, v-strikeGap)});
      padding-top: map-get($mapScope, gap);
      position: relative;
      &::before,
      &::after {
        content: "";
        left: 0;
        position: absolute;
        width: map-get($mapScope, v-strikeLength);
      }
      &::before {
        border-left: map-get($mapScope, strikeWidth) solid map-get($mapScope, strikeColor);
        border-bottom: map-get($mapScope, strikeWidth) solid map-get($mapScope, strikeColor);
        height: calc(0.5em + #{map-get($mapScope, gap)});
        top: 0;
      }
      &:not(:last-of-type) {
        &::after {
          border-left: map-get($mapScope, strikeWidth) solid map-get($mapScope, strikeColor);
          height: 100%;
        }
      }
    }
  }
  ul.listify {
    list-style: none;
    margin: 0;
    padding-left: 1rem;
    overflow-y: auto;
  }
  ul.listify > li {
    line-height: 1;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    position: relative;
  }
  ul.listify > li::before,
  ul.listify > li::after {
    content: "";
    left: 0;
    position: absolute;
    width: 0.5rem;
  }
  ul.listify > li::before {
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    height: calc(0.5em + 0.5rem);
    top: 0;
  }
  ul.listify > li:not(:last-of-type)::after {
    border-left: 1px solid #000;
    height: 100%;
  }
}

@mixin quill {
  .ql-toolbar.ql-snow {
    position: absolute;
    top: 15px;
    right: -1px;
    padding: 2px;
    border: 0;
    box-shadow: 0px 0px 10px rgb(218, 218, 218);
    border-radius: 20px;
    .ql-formats {
      margin-right: 0;
      .ql-font,
      .ql-underline {
        display: none;
      }
      .ql-size {
        border-radius: 21px;
        width: 60px;
        background: #eee;
        font-size: 10px;
      }
      button {
        background: #eee;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        margin: 0 3px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .ql-container.ql-snow {
    border: 0;
  }
  .ql-editor {
    padding: 20px 0;
  }
  .ql-editor.ql-blank::before {
    left: 0;
    bottom: 0;
    font-style: normal;
    color: #777;
    font-size: 12px;
  }
}
