@import "variables.scss";
@import "mixins.scss";

body {
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.dashboard-options-button {
    background-color: #5f7884;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.dashboard-options-button:hover {
    background-color: #000;
    cursor: pointer;
}

.react-bootstrap-table th span  {
    color: #5a5a5a;
    font-size: 22px;
    padding: 5px;
}

.react-bootstrap-table th .sortable  {
    color: #5a5a5a;
    font-size: 30px;
}

.button-selected {
    background-color: #008ED6;   
}

.questiontip {
    width: 12px;
    margin: 0px 4px 8px 4px;
}

.item-active {
    font-size: 15px;
    font-weight: 700;
    border-right: #000 1px solid;
}

.smooth-show{
    animation: smooth-animation .3s linear;
}

.analisi-results-button {
    position: absolute;
    top: 14px;
    left: 520px;
    font-size: 16px;
    font-weight: 100;
}

@keyframes smooth-animation {
    from{
        opacity: 0.5;
    }
    to{
        opacity: 1;
    }
}

@keyframes scaling-animation {
    from { transform: scale(1); }
    50% { transform: scale(0); }
    to { transform: scale(1); }
}

.skeleton-main-video{
    width: 100%;
    height: 100%;
    animation: skeleton-animation 1s linear infinite alternate;
    border-radius: 10px;
}

.skeleton-main-image {
    width: 700px;
    height: 500px;
    animation: skeleton-animation 1s linear infinite alternate;
    border-radius: 10px;
}

.skeleton-no-analisys-image {
    width: 700px;
    height: 300px;
    animation: skeleton-animation 1s linear infinite alternate;
    border-radius: 10px;
}

.skeleton-carousel{
    width: 80px;
    height:80px;
    animation: skeleton-animation 1s linear infinite alternate;
    border-radius: 10px;
}

@keyframes skeleton-animation {
    from{
        background-color: #d6d6d6;
    }
    to{
        background-color: #f0f3f5;
    }
}

.remove {
            cursor: pointer;
            position: absolute;
            right: -15px;
            bottom: -15px;
            width: 35px;
            height: 35px;

            &:hover {
                #btn-2 {
                    fill: #000;
                }
            }
        }

#color-picker {
  padding: 0;
  border: none;
// box-shadow: none;
  border-radius: 50px;
  width: 20px;
  height: 20px;
}
#color-picker::-webkit-color-swatch {
  width: 35px;
  height: 35px;  
//   border: none;
// box-shadow: none;
  border-radius: 50px;
  padding: 0;
}
#color-picker::-webkit-color-swatch-wrapper {
  width: 35px;
  height: 35px;  
//   border: none;
// box-shadow: none;
  border-radius: 50px;
  padding: 0;
}
.modal-redirect {
    .input-label {
        font-weight: bold;
        color: #000;
    }
    .label {
        color: #000;
        font-weight: normal;
        font-size: 16px;
    }
    .input {
        // border: none;
        outline: none;
        border-bottom: 0.5px solid #b7b7b7;
        border-top: none;
        border-left: none;
        border-right: none;
    }
}
// .icon-play {
//     width: 100%;
//     position: absolute;
//     top: 0px;
//     left: 0px;
//     right: 0px;
//     bottom: 0px;
//     margin: auto;
//     text-align: center;
//     // box-sizing: border-box;
// }
// .icon-play-i {
//     height: 480px;
//     width: 640px;
//     margin-left: 10px;
// }
// .icon-play-i-full-screen {
//     height: 85vh;
//     width: 640px;
//     margin-left: 10px;
// }
// .video-overlay{
//     opacity: .3;
//     // z-index: 2;
// }
.image-uploader{
    box-shadow: 1px 5px 10px 0.3px rgba(0, 0, 0, 0.4);
}

.shelf-uploader{
    box-shadow: 1px 5px 10px 0.3px rgba(0, 0, 0, 0.4);
    padding: 20px;
    border-radius: 10px;
}


.imageCorusel{
    width: 30px !important;
}
.imageCorusel:hover{
    opacity: 0.7;
    cursor: pointer;
}

.skeleton-loading-bar{
    animation: skeleton-loading-animation 1s linear infinite alternate;
    width: 90%;
    background-color: #adb5bd;
    border-radius: 15px;
}

.skeleton-loading {
    animation: skeleton-loading-animation 1s linear infinite alternate;
    width: 90%;
    background-color: #adb5bd;
    border-radius: 15px;
}

@keyframes skeleton-loading-animation {
    from {
        background-color: hsl(200, 20%, 80%);
    }
    to {
        background-color: hsl(200, 20%, 95%);
    }
}

.rotateImage {
    -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.textInput {
    margin-top: 15px !important;
    width: 800px !important;
    border-radius: 40px!important;
    font-weight: bold!important;
    font-size: 16px!important;
    color: $colorBlack !important;
    box-shadow: 0px 0px 10px #aaa!important;
  }

.numberInput {
  margin: 20px !important;
  width: 80px !important;
  border-radius: 40px!important;
  font-weight: bold!important;
  font-size: 16px!important;
  color: $colorBlack !important;
  box-shadow: 0px 0px 10px #aaa!important;
}

#behaviourSelect {
    max-width: 100px;
    padding: 0.45rem 0.9rem;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    background-clip: paddingBox;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: white !important;
}
.no-scroll {
  overflow-y: hidden;
  overflow-x: hidden;  
}
.scroll {
  max-height: 500px;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
    &::-webkit-scrollbar {
        width: 1rem;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: 0 0 6px #6666664d;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #9a9999;
        outline: 1px solid #fcfcfc;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #c3c1c1 !important;
        outline: 1px solid #fcfcfc !important;
        border-radius: 10px !important;
    }
}

.analysis-page {

    h2, h3, h4, h5, button{
        text-transform: uppercase;
    }

    .analysis--table-title {
        border-radius: 15px;
        background-color: #6bbbc5;
        color: white;
        opacity: .8;
        font-weight: bold;
    }

    .analysis-table {
        height: 30rem;
        overflow-x: scroll;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 1rem;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            box-shadow: 0 0 6px #6666664d;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #9a9999;
            outline: 1px solid #fcfcfc;
            border-radius: 10px;
        }
        // &:hover {
        //     background-color: #b2b2b2;
        // }
    }

    .reset-button{
        background-color: #ff0000d7;
        border: none;
        border-radius: 20px;
        color: white;
    }
    
    .disabled{
        background-color: #adadad;
    }
}

.margin{  
  margin-top: 50px !important;
}

.marginLeft {  
  margin-left: 40px !important;
  //padding: 100px;
}

.fill {
  max-width:100% !important;
}

.e-float-input,
.e-float-input.e-control-wrapper {
    margin-top: 5px;
}

.license-tab {
    cursor: pointer;
}
.active-license-tab {
    background-color: $colorGreen;
    color: white;
}
.license-tab:hover {
    transition: 0.2s ease-in-out;
    color: $colorWhite;
    background-color: $colorGreen;
}

.error-feedback {
    color: #ff5b5b;
    font-size: 16px;
}

.success-feedback {
    color: #008ed6;
    font-size: 16px;
}

.modal-content {
    @include modal;
}

.new-button {
    position: absolute !important;
    left: 190px;
    top: 100px;
    z-index: 20;

    .button-crea {
        border: 0;
        border-radius: 50%;
        background-color: transparent;

        svg {
            width: 100px;
            height: 100px;
        }

        &:hover {
            .st0 {
                fill: #000;
            }
        }
    }
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

// upload-button components
.upload-button {
    border: 0;
    background: transparent;
}

.on-button {
    border-radius: 20px;
    border: 2px solid #008ed6;
    width: 85px !important;
    height: 85px !important;
    object-fit: cover;

    &.img-info {
        border: 0;
        width: 125px !important;
        height: 125px !important;
        background: transparent;
    }
    &.analisiImage {
        margin: 5px;
        width: 65px !important;
        height: 65px !important;
    }
}

.cart-section {
    border: 0;
    border-radius: 40px 0px 0px 40px;
    z-index: 1000;
    box-shadow: 0px 0px 10px #dadada;
    background-color: #ffffff;

    .carrello {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        font-family: $fontFamily;
    }

    .carrello-subtitle {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        font-family: $fontFamily;
    }

    .delete-button {
        border: 0;
        background: transparent;

        svg {
            color: #e46a5d;

            &:hover,
            &:active {
                color: $colorBlack !important;
            }
        }
    }

    .number-cart {
        border-radius: 6px;
        border: 0;
        background: #f2f2f2 !important;
        text-align: center;
        height: 30px;
        width: 80%;
        font-size: 14px;

        &::placeholder {
            font-size: 14px;
            color: #1a1a1a;
        }
    }

    .list-products {
        height: 200px;
        overflow: hidden auto;

        p {
            font-size: 14px;
        }
    }

    .line {
        height: 2px;
        background-color: #dedede;
    }

    .totale {
        font-size: 26px;
    }

    .sum {
        font-weight: bold;
        font-size: 26px;
    }

    .button-save {
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 15px;
        background: $colorGrey;
        outline: none;
        border: 0;
        font-weight: bold;
        color: $colorWhite;
        &:hover {
            background: #1a1a1a;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.button-create {
    background-color: #008ED6;
    border: 0;
    border-radius: 50%;
    color: white;
    width: 118px;
    height: 118px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

.button-create:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-delete {
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 15px;
    background: red;
    outline: none;
    border: 0;
    font-weight: 500;
    color: white;
}

.modal-dialog.modal-cart {
    position: absolute;
    right: 0px;
    top: 25px;

    .modal-content {
        background-color: transparent;
        width: 400px;
    }
}

.login-section {
    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear {
        display: none;
    }
    background-color: #c2d9e8;
    color: $colorBlack;
    
    .password-reveal-icon {
        cursor: pointer;
    }
    .title {
        font-size: 30px;
        color: $colorBlack;
    }

    .forgot-pass {
        font-size: 14px;
        color: $colorBlack;
        font-weight: bold;
    }

    input#checkbox {
        width: 15px;
        height: 15px;
        border: 0;
    }

    .av-invalid,
    .av-valid {
        .input,
        .form-control {
            border: 0;
            border-radius: 40px;
            background-color: #fff;
            outline: none;
            height: 50px;
            color: $colorBlack;

            &::placeholder {
                color: $colorBlack;
            }
        }

        // input#password,
        input#username,
        input#email {
            background: transparent;
            border: 0;
            color: #000;

            &::placeholder {
                color: #000;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0 30px #ffffff inset !important;
            -webkit-text-fill-color: #000 !important;
            border-radius: 40px;
            background-color: #fff;
            outline: none;
            height: 50px;
        }
    }

    .btn-login {
        border: 0;
        border-radius: 40px;
        font-size: 14px;
        background-color: #008ed6;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        height: 50px;

        &:hover {
            background-color: #000;
            color: #fff;
        }
    }
}

.dashboard-section {
    .input-group {
        border: 0;
        border-radius: 40px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        background: #fafafa;
        height: 40px;
    }

    .input {
        border: 0;
        border-radius: 40px;
        outline: none;
        background: #fafafa;
        height: 40px;

        &::placeholder {
            color: #000;
        }

        &:focus {
            background: #fafafa;
        }
    }

    .search-button {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 40px;
        background: #5f7884;
        color: $colorWhite;

        &:hover {
            background: #495c66;
        }

        &:active {
            background: #37454c;
        }
    }

    .filter-button {
        border: 0;
        background-color: #fafafa;
        border-radius: 40px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        .name {
            font-size: 14px;
            color: #1a1a1a;
            position: absolute;
            margin-left: 15px;
        }

        &:hover,
        &.active {
            .name {
                color: #fff;
            }

            #filtri_incorso {
                .st0 {
                    fill: #6dbec6;
                }
            }

            #filtri_bozze {
                .st0 {
                    fill: #f0b24f;
                }
            }

            #filtri_completati {
                .st0 {
                    fill: #e46a5d;
                }
            }

            #filtri_ordine {
                .st0 {
                    fill: #5f7884;
                }
            }

            .ordine {
                color: #fff;
            }

            &.open {
                background-color: #6dbec6;
            }

            &.draft {
                background-color: #f0b24f;
            }

            &.closed {
                background-color: #e46a5d;
            }

            &.order {
                background-color: $colorGrey;
            }
        }

        &.grid {
            width: 50px;
            margin: 0 3px;
            height: 35px;

            svg {
                height: 30px;

                g#view_mode rect {
                    fill: $colorGrey;
                }
            }

            &.active,
            &:hover {
                background-color: $colorGrey;

                svg {
                    path {
                        fill: $colorGrey;
                    }

                    g#view_mode rect,
                    g#Color rect {
                        fill: #fff;
                    }
                }
            }
        }

        .ordine {
            position: absolute;
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #1a1a1a;
            margin-right: 15px;
        }
    }

    svg {
        color: #fff;
    }

    .card {
        background-color: #fafafa;
        border-radius: 10px;
        // border: 0;
        box-shadow: 0 0 10px #d0d0d0;

        .study {
            margin-left: -12px;
            margin-top: -18px;

            svg {
                width: 55px;
            }
        }

        .list {
            // margin-left: -13px;
            // margin-top: -32px;

            svg {
                width: 40px;
            }
        }

        .icon {
            border: 0;
            background-color: transparent;

            &.disabled {
                &:hover {
                    cursor: default;

                    svg circle {
                        fill: rgb(131, 148, 157);
                    }
                }
            }

            svg {
                width: 40px;
                height: 40px;
            }

            &:hover {
                svg circle {
                    fill: $colorBlack;
                }
            }
        }

        .title {
            font-size: 16px;
        }

        .description {
            height: 30px;
        }

        .actions {
            background-color: #e3e3e3;
            border-radius: 0 0 12px 12px;

            p {
                font-size: 9px;
            }

            &.list {
                background-color: transparent;
            }
        }
    }

    .page-item.active .page-link {
        background-color: #008ed6;
        border-color: #008ed6;
    }
}

.studies-section {
    @include listify;

    .form-group {
        @include form-group;
    }

    .title {
        font-family: $fontFamily;
        font-size: 30px;
        color: $colorBlack;
    }

    .flag-icon {
        border-radius: 50%;
    }

    .button-save {
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 14px;
        background: $colorGrey;
        outline: none;
        border: 0;
        font-weight: bold;
        color: $colorWhite;

        &:hover {
            background: #1a1a1a;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    #limitParticipants,
    #consumeParticipants {
        font-weight: normal;
        text-transform: inherit;
    }

    .tema {
        border-radius: 10px;
        cursor: pointer;

        &.blue {
            background-color: $temaBlue;
        }

        &.green {
            background-color: $temaGreen;
        }

        &.yellow {
            background-color: $temaYellow;
        }

        &.purple {
            background-color: $temaPurple;
        }

        .type {
            text-transform: uppercase;
            color: $colorBlack;
            padding-left: 60px;
        }

        svg {
            color: $colorBlack;
        }

        .add {
            border-radius: 10px;
            width: 50px;
            height: 50px;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &.blue {
                background-color: $colorBlue;
            }

            &.green {
                background-color: $colorGreen;
            }

            &.yellow {
                background-color: $colorYellow;
            }

            &.purple {
                background-color: $colorPurple;
            }

            svg {
                color: $colorWhite;
            }
        }

        &:hover {
            height: 50px;

            &.blue {
                background-color: $colorBlue;
            }

            &.green {
                background-color: $colorGreen;
            }

            &.yellow {
                background-color: $colorYellow;
            }

            &.purple {
                background-color: $colorPurple;
            }

            .type {
                color: $colorWhite;
                font-weight: bold;
                padding-left: 80px;
                transition: padding 0.5s ease-out;
            }

            .add {
                display: none;
            }
        }
    }

    .color {
        &.blue {
            color: $colorBlue;
        }

        &.green {
            color: $colorGreen;
        }

        &.yellow {
            color: $colorYellow;
        }

        &.purple {
            color: $colorPurple;
        }
    }

    .e-error {
        font-size: 10px !important;
        color: red !important;
    }

    #participants {
        &::placeholder {
            color: $colorBlack;
            font-size: 16px;
            font-weight: bold;
        }
    }

    #welcomeMessage {
        @include quill;
    }

    #thanksMessage {
        @include quill;
    }
}

.edit-study,
.edit-test {

    .study-name {
        font-weight: bolder !important;
    }

    ::-webkit-scrollbar {
        /*display: none;*/
    }

    .button-save.continua {
        background: #008ed6;
    }

    #notes {
        @include quill;
    }
}

.left-side-section {
    overflow-x: hidden !important;
    position: fixed;
    background: #e3e3e3;
    width: 260px;
    height: 100vh;
    z-index: 100;
    top: 0;

    .logo-section {
        height: 70px !important;
        width: 260px !important;

        img {
            margin-top: 17px !important;
            margin-left: 29px;
        }
    }

    .button,
    .button:focus,
    .button:hover,
    .button:active {
        padding: 0;
        box-shadow: none !important;
        background-color: transparent !important;
        height: 50px;
        border: 0 !important;
        outline: none;
        text-transform: uppercase !important;
        font-weight: bold !important;
        font-size: 18px !important;
    }

    // @media screen and (max-width: 736px) {
    //     display: none;
    // }

    @include listify;

    .listify {
        li {
            cursor: pointer;
        }
    }

    .button-save,
    .button-save:focus {
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 14px;
        outline: none;
        border: 0;
        font-weight: bold;
        background: $colorGrey;
        color: $colorWhite;
        box-shadow: none;

        &.continua {
            background: #008ed6;
        }
    }
}

.left-side-section-open {
    transition: .2s;
    left: 0%;
}

.left-side-section-close {
    transition: .4s;
    left: -100%;
}

.welcome-section {
    border: 0;
    background-color: $colorWhite;
    position: absolute !important;
    left: 0;
    z-index: 100;
    top: 0;
    bottom: 0;

    @include quill;

    .logo-section {
        height: 70px !important;
        width: 260px !important;

        img {
            margin-top: 17px !important;
            margin-left: 29px;
        }
    }

    .button-save {
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 14px;
        background: $colorGrey;
        outline: none;
        border: 0;
        font-weight: bold;
        color: $colorWhite;

        svg {
            width: 25px;
            height: 20px;
        }

        &:hover {
            background: $colorBlack;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.continua {
            background: #008ed6;

            &:hover,
            &:active,
            &:focus {
                background-color: $colorBlack;
            }
        }
    }
}

.partire-section {
    background-color: #f2f2f2;
    position: absolute !important;
    left: 0;
    z-index: 100;
    top: 0;
    bottom: 0;

    &.erogazione {
        background-color: #ffffff;

        .product {
            color: #008ed6;
            font-weight: bold;
            font-size: 24px;
        }

        .zoom-backgrnd {
            background: transparent;
            border: 0;

            .zoom {
                &:hover {
                    g path {
                        stroke: #000;
                    }
                }
            }

            #Component_23_1 {
                .st0 {
                    fill: #fff;
                }

                .st2 {
                    fill: #000;
                }

                .st3,
                .st4 {
                    stroke: #008ed6;
                }

                &:hover,
                &.active {
                    .st0 {
                        fill: #008ed6;
                    }

                    .st3,
                    .st4 {
                        stroke: #fff;
                    }
                }
            }
        }

        .shelf-container {
            .label-price {
                position: absolute;
                font-size: 8px;
                font-weight: bold;
                bottom: -12px;
                z-index: 10;
                margin-bottom: 0;
                background-color: #fff;
                padding: 0 15px;
            }

            .shelfImages {
                position: relative;
                width: 100%;
                margin: 0 25px;

                &:before {
                    content: "";
                    background: url("../assets/images/erogazione/finale_shelf_sinistra.svg");
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 20px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -20px;
                    z-index: 10;
                }

                &:after {
                    content: "";
                    background: url("../assets/images/erogazione/finale_shelf_destra.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 20px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -20px;
                    z-index: 10;
                }
            }

            .shelfRow {
                background: url("../assets/images/erogazione/ripiano_shelf.svg");
                background-size: auto 100%;
            }

            .imagesByRow:last-child {
                .shelfBase {
                    background: url("../assets/images/erogazione/base_shelf.svg");
                    background-size: cover;
                    width: 100%;
                    height: 50px;
                }
            }
        }

        .video-test {
            pointer-events: none;

            .camera {
                position: absolute;
            }

            .error {
                color: #fff;
                font-weight: bold;
                font-size: 18px;
                position: absolute;
                background-color: #ff0000bb; /*#008ed6bb;*/
            }
        }

        .video-display {
            position: relative;
            height: 500px;
        }

        .button-save {
            border-radius: 40px;
            text-transform: uppercase;
            font-size: 15px;
            background: $colorGrey;
            outline: none;
            border: 0;
            font-weight: bold;
            color: $colorWhite;

            &:hover {
                background: #1a1a1a;
            }

            &:active,
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .button,
        .button:focus,
        .button:hover,
        .button:active {
            padding: 0;
            box-shadow: none !important;
            background-color: transparent !important;
            height: 50px;
            border: 0 !important;
            outline: none;
            text-transform: uppercase !important;
            font-weight: bold !important;
            font-size: 18px !important;
        }

        .screen {
            height: 80vh;
        }
    }

    .logo-section {
        height: 70px !important;
        width: 260px !important;

        img {
            margin-top: 17px !important;
            margin-left: 29px;
        }
    }

    .subtitle {
        font-family: $fontFamily;
        font-size: 30px;
        color: $colorBlack;
        text-transform: uppercase;
        font-weight: normal;
        margin-top: 10%;
    }

    .testare-button {
        cursor: pointer;

        &:hover {
            .st0 {
                fill: #008ed6;
            }

            .st2 {
                fill: #fff;
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }
}

.modal-dialog.fullscreen-modal {
    max-width: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
}

.erogazione-menu-section {
    background-color: #fff;
    position: absolute !important;
    left: 0;
    z-index: 100;
    top: 0;
    bottom: 0;

    &.erogazione {
        background-color: #ffffff;

        .zoom-backgrnd {
            background: transparent;
            border: 0;
        }

        .zoom {
            &:hover {
                g path {
                    stroke: #000;
                }
            }
        }
    }

    .logo-section {
        height: 70px !important;
        width: 260px !important;

        img {
            margin-top: 17px !important;
            margin-left: 29px;
        }
    }

    .subtitle {
        font-family: "Open Sans", sans-serif;
        font-size: 32px;
        color: #1a1a1a;
        font-weight: lighter;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 8%;
        margin-bottom: 5%;
    }

    .erogazione-menu-button {
        border: 0;
        text-transform: uppercase;
        font-family: $fontFamily;
        font-size: 30px;
        font-weight: normal;
        border-radius: 20px;
        background-color: #e3e3e3;
        color: #000;
        width: 250px;
        height: 250px;

        &:hover,
        &:focus,
        &:active {
            background-color: #008ed6;
            color: #fff;
        }

        &.disabled {
            cursor: no-drop;

            &:hover,
            &:focus,
            &:active {
                background-color: #e3e3e3;
                color: #000;
            }
        }
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-product {
    margin: 0 auto;
    overflow: hidden auto;

    label {
        font-weight: bold;
        text-transform: uppercase;
        color: #1a1a1a;
    }

    .react-transform-component {
        width: 100% !important;
    }

    .react-transform-element {
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .name {
        font-size: 20px;
        color: #1a1a1a;
    }

    .input-text {
        border-bottom: 1px solid #ccc;
        text-align: left;
        font-size: 16px;
        color: #1a1a1a;
        text-transform: none;
    }

    .product {
        width: auto;
        height: 350px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border-radius: 4px;
        }
    }

    .addCart {
        border: 0;
        background: #008ed6;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;

        &:hover {
            background: #000;
        }
    }

    .image {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        border: 1px solid #ccc;

        &.active {
            border: 2px solid #008ed6;
            box-shadow: 0 0 5px #ccc;
        }

        .no-image {
            width: 80px;
            height: 80px;
            border-radius: 8px;
            font-size: 16px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border-radius: 8px;
        }
    }

    .amount {
        background: #e3e3e3 !important;
        border-radius: 40px;
        font-size: 16px;
        box-shadow: 0 0 10px #aaa;
        border: 0;
        color: #1a1a1a;
    }

    .amount-button {
        border: 0;
        background: transparent;
    }
}

.stimoli-section {
    ::-webkit-scrollbar {
        display: none;
    }

    .study-name {
        font-weight: bolder !important;
    }

    .form-group {
        @include form-group;
    }

    #notes {
        @include quill;
    }

    .on-background {
        background: transparent;
        border: 0;
        position: relative;

        .edit {
            cursor: pointer;
            position: absolute;
            right: -15px;
            top: -15px;
            width: 40px;
            height: 40px;

            &:hover {
                #btn-2 {
                    fill: #000;
                }
            }
        }

        .image-number {
            border-radius: 50%;
            background-color: #008ED6;
            text-align: center;
            color: white;
            position: absolute;
            left: -5px;
            top: -15px;
            width: 20px;
            height: 20px;
        }

        .remove {
            cursor: pointer;
            position: absolute;
            right: -15px;
            bottom: -15px;
            width: 35px;
            height: 35px;

            &:hover {
                #btn-2 {
                    fill: #000;
                }
            }
        }
    }

    .number {
        background: #e3e3e3 !important;
        border-radius: 40px;
        font-weight: bold;
        font-size: 16px;
        color: $colorBlack !important;
        box-shadow: 0px 0px 10px #aaa;
        border: 0;
    }

    .position {
        .e-input-group {
            background: #e3e3e3;
            border-radius: 40px;
            font-weight: bold;
            font-size: 16px;
            color: $colorBlack !important;
            box-shadow: 0px 0px 10px #aaa;
        }
    }

    .carica-container{
        gap:64px;
    }

     .carica-divider{
            font-style: italic;
            font-size: 22px;
            line-height: 37px;
            font-weight: 600;
        }

    .carica-button{
        width: 150px;
        height:150px;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        gap:10px;
        align-items: center;
        border: 3px solid #5F7884;
        border-radius: 50%;
        color:#5F7884;
  
        font-weight: 600;
        text-align: center;
        cursor: pointer;

        .st0{
            fill:#5F7884;
        }
     
       

        .text-bold{
            font-weight: 800;
            margin-bottom: 0;
        }
        &:hover{
            .st0{
             fill:#fff !important;
             height:100px;
             width: 100px;
            }
            color:#fff;
            background-color: #1A1A1A;
            border:3px solid transparent;

          
           
        }

    }

    .carica-backgrnd {
        border: 1px solid #dadada;
        border-radius: 20px;
        height: 200px;

        .button-carica {
            border: 0;
            width: 70px;
            height: 70px;
            box-shadow: 0px 0px 10px #dadada;
            color: #fff;
            border-radius: 50px;
            background-color: #008ed6;

            &:hover {
                background-color: #5f7884;
            }
        }
    }

    .dis-backgrnd {
        border: 1.5px dashed #000000;
        border-radius: 20px;
        padding: 30px 0px;
        width: 80px;
        height: 80px;
        margin: 5px 12px 0px 11px;

        &.dis-analisiImage {
            padding: 15 !important;
            width: 65px !important;
            height: 65px !important;
        }

        .dis-button {
            border: 0;
            background: transparent;
        }
    }

    .slider-backgrnd {
        // border-radius: 20px;
        padding: 30px 0px;
        height: 80px;

        .slider {
            background: transparent;
            border: 0;

            svg {
                background-color: #008ED6;
                border-radius: 50%;
                width: 30px;
                height: 30px;
            }
            
            g path {
                stroke: #fcfcfc
            }

            &:hover {
                svg {
                    border: 2px solid #000;
                    // transition: 50ms ease-out;
                    background-color: #fff;
                }
                g path {
                    stroke: #000;
                }
            }
        }

        .slider-off {
            // background: transparent;
            border: 0;
            svg {
                border: 2px solid #cfcfcf;
                border-radius: 50%;
                width: 30px;
                height: 30px;
            }
        }
    }

    .form-group {
        @include form-group;
    }

    .enabled {
        svg {
            box-shadow: 0px 0px 10px #dadada;
            border-radius: 28px;

            path:nth-child(1) {
                fill: #008ed6;
            }

            #Union_3,
            #Path_1895,
            #Path_1896 {
                fill: #fff;
            }

            g path {
                fill: #fff;
                stroke: #fff;
                background-color: #fff;
            }
        }
    }
    
    .disabled {
        svg {
            g path {
                fill: #d1d1d1;
                stroke: #fff;
                background-color: #fff;
            }
        }
        color: #e3e3e3;
    }

    .costruisci-background {
        border: 0;
        background: transparent;

        &.disabled {
            pointer-events: none;
        }
    }

    .costruisci-button-2 {
        width: 200px;
        height: 200px;
        position: relative;
        top: 7px;
        cursor: pointer;

        .st0,
        .st1 {
            stroke-width: 0;
            fill: #5F7884;   
        }

        .st2 {
            fill: transparent;
            stroke: #5F7884;
        }

        &:hover {
            #btn-2_1_ {
                .st2 {
                    fill: #000;
                    stroke-width: 0;
                }
            }

            .st0,
            .st1,
            .st2 {
                fill: #fff;
            }
        }
    }

    .costruisci-button {
        width: 200px;
        height: 200px;
        cursor: pointer;


      .st0{
        stroke:#5F7884 ;
        fill:transparent;
      }
      .st1,.st2 {
            stroke-width: 0;
            fill: #5F7884;
        }


        &:hover {
            #btn-2 {
                .st0 {
                    fill: #000;
                    stroke-width: 0;
                }
            }

            .st0,
            .st1,
            .st2 {
                fill: #fff;
            }
        }
    }

    .video-background {
        border: 0;
        background: transparent;
    }

    .video-upload-button {
        width: 150px;
        height: 150px;

        .st0 {
            fill: #008ed6;
        }

        .st1 {
            fill: #fff;
            stroke: #fff;
            stroke-width: 1;
        }

        &:hover {
            .st0 {
                fill: #000;
            }
        }
    }

    .oppure {
        text-transform: uppercase;
        font-family: $fontFamily;
        font-weight: bold;
        font-size: 17px;
    }

    .title {
        font-family: "Open Sans", sans-serif;
        font-size: 38px;
        color: #1a1a1a;
        font-weight: normal;
        letter-spacing: 1px;
    }

    .subtitle {
        font-family: $fontFamily;
        font-size: 18px;
        color: $colorBlack;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .text-field {
        font-family: $fontFamily;
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .button-save {
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 14px;
        background: $colorGrey;
        outline: none;
        border: 0;
        font-weight: bold;
        color: $colorWhite;

        svg {
            width: 25px;
            height: 20px;
        }

        &:hover {
            background: $colorBlack;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.btn-secondary.disabled,
        &.btn-secondary:disabled {
            background-color: #5f7884 !important;
            border-color: #5f7884 !important;
        }

        &.btn.disabled,
        &.btn:disabled {
            opacity: 1 !important;
        }
    }

    .testare-button {
        border: 0;
        background: transparent;

        &.active,
        &:focus,
        &:hover {
            svg {
                box-shadow: 0px 0px 10px #dadada;
                border-radius: 28px;

                path:nth-child(1) {
                    fill: #008ed6;
                }

                #Union_3,
                #Path_1895,
                #Path_1896 {
                    fill: #fff;
                }

                g path {
                    fill: #fff;
                    stroke: #fff;
                    background-color: #fff;
                }

                g rect {
                    fill: #fff;
                    stroke: #fff;
                    background-color: #fff;
                }
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }

    .disabled-tool-button {
        width: 140px;
        height: 180px;
        pointer-events: none;

         .st2,.st1,.st0{
            stroke: none;
         }

        .st1 {
            fill: #e3e3e3;
            stroke: #e3e3e3;
        }

        .st3 {
            fill: #d0d0d0;
        }
    }

    .tool-button {
        border: 0;
        background: transparent;
        width: 140px;
        height: 180px;
        cursor: pointer;

        .st2,.st1,.st0{
            stroke: none;
         }

        &.active,
        &:hover {
            #tool2 {
                .st0 {
                    fill: #f1b557;
                }

                .st1 {
                    stroke: #fff;
                    stroke-width: 0;
                }

                .st2 {
                    fill: #fff;
                }
            }

            .tool3 {
                .st0 {
                    fill: #67a1c6;
                }

                .st1 {
                    stroke: #fff;
                    stroke-width: 0;
                }

                .st2 {
                    fill: #fff;
                }
            }

            .tool4 {
                .st0 {
                    fill: #6dbec6;
                }

                .st1 {
                    stroke: #fff;
                    stroke-width: 0;
                }

                .st2 {
                    fill: #fff;
                }
            }

            .tool5 {
                .st0 {
                    fill: #e46a5d;
                }

                .st1 {
                    stroke-width: 0;
                }

                .st2,
                .st4 {
                    stroke: #fff;
                }

                .st13,
                .st5 {
                    fill: #fff;
                }
            }

            .tool6 {
                .st0 {
                    fill: #af81cc;
                }

                .st1,
                .st2 {
                    fill: #fff;
                    stroke-width: 0;
                }
            }
        }

        .tool2 {
            .st0 {
                fill: #fff;
            }

            .st1 {
                fill: none;
                stroke: #f1b557;
                stroke-width: 3;
            }

            .st2 {
                fill: #f1b557;
            }
        }

        .tool3 {
            .st0 {
                fill: #fff;
            }

            .st1 {
                fill: none;
                stroke: #67a1c6;
                stroke-width: 3;
            }

            .st2 {
                fill: #67a1c6;
            }
        }

        .tool4 {
            .st0 {
                fill: #fff;
            }

            .st1 {
                fill: none;
                stroke: #6dbec6;
                stroke-width: 3;
            }

            .st2 {
                fill: #6dbec6;
            }
        }

        .tool5 {
            .st0 {
                fill: #fff;
            }

            .st1 {
                fill: none;
                stroke: #e46a5d;
                stroke-width: 3;
            }

            .st2 {
                fill: #e46a5d;
            }
        }

        .tool6 {
            .st0 {
                fill: #fff;
            }

            .st1,
            .st2 {
                fill: #af81cc;
                stroke-width: 0;
            }
        }
    }

    .info-input {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        color: #000;
        font-size: 16px;
    }

    .random {
        .button-random {
            position: relative !important;
            top: 48px;
            left: 40px;
            height: 30px;
            font-size: 9px;
            font-weight: normal;
            border: 0;
            border-radius: 20px;
            background: #fafafa;
            font-size: 10px;
            box-shadow: 0px 0px 10px #dedede;

            .off-backgrnd {
                background-color: transparent;
                color: #5f7884;
            }

            &.active,
            &:hover {
                background-color: #5f7884;
                color: #fff;
            }

            svg {
                padding: 2px;
                width: 18px;
                height: 18px;
                background-color: #5f7884;
                fill: #fff;
                border-radius: 60px;
            }
        }
    }

    .slider-zoom-cont {
        border-radius: 20px;
        padding: 30px 0px;
        height: 80px;

        .slider-zoom {
            background: transparent;
            border: 0;

            svg {
                width: 30px;
                height: 30px;
            }

            &:hover {
                g path {
                    stroke: #000;
                }
            }
        }
        }

    .opzioni-section {
        box-shadow: 0px 0px 10px #dadada;
        border-radius: 8px;

        label {
            text-transform: uppercase;
        }

        .input-group-text {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            background: transparent;
        }

        .subtitle {
            font-family: $fontFamily;
            font-size: 15px;
            color: $colorBlack;
            text-transform: uppercase;
            font-weight: bold;
        }

        .add-background {
            border: 1px solid #dadada;
            border-radius: 20px;
            width: 125px;
            height: 125px;

            .add-image {
                display: flex;
                justify-content: center;
                border: 0;
                width: 50px;
                height: 50px;
                font-size: 30px;
                font-weight: lighter;
                box-shadow: 0px 0px 10px #dadada;
                color: #fff;
                border-radius: 50px;
                background-color: #a2a2a2;

                &:hover {
                    background-color: #1a1a1a;
                }
            }
        }

        .info-input {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }

        .input-group {
            border-radius: 0;
        }

        .formati {
            color: #888888;
        }

        .p2 {
            margin-left: 60px;
        }

        .p1 {
            margin-left: 68px;
        }

        .score {
            font-size: 80px;
            font-weight: bold;
            color: #5f7884;
        }

        .radio {
            box-shadow: none;

            .radio-items {
                font-size: 14px;
                text-transform: none;
                font-weight: normal;
            }
        }

        .off-backgrnd {
            border: 1px solid #008ed6;
            border-radius: 20px;
            padding: 30px 0px;
            height: 80px;

            .off-button {
                border: 0;
                background: transparent;
            }
        }

        .slider-backgrnd {
            border-radius: 20px;
            padding: 30px 0px;
            height: 80px;

            .slider {
                background: transparent;
                border: 0;

                svg {
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    g path {
                        stroke: #000;
                    }
                }
            }
        }

        .dis-backgrnd {
            border: 1px dashed #878787;
            border-radius: 20px;
            padding: 30px 0px;
            height: 80px;


            .dis-button {
                border: 0;
                background: transparent;
            }
        }
    }

    .mouseclick-section {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;

        .number {
            background: #e3e3e3;
            border-radius: 40px;
            font-weight: bold;
            font-size: 16px;
            color: $colorBlack !important;
            box-shadow: 0px 0px 10px #aaa;

            &.durata {
                margin-left: 55px;
            }
        }

        .radio {
            box-shadow: none;

            .radio-items {
                font-family: $fontFamily;
                font-size: 15px;
                text-transform: none;
                font-weight: normal;
                letter-spacing: 0.5px;
            }
        }

        p {
            text-transform: uppercase;
            font-family: $fontFamily;
        }

        .mouseclick-header {
            background-color: #67a1c6;
            border-radius: 8px;

            svg {
                width: 25px;
                height: 25px;

                g path {
                    fill: #fff;
                }
            }

            .mouseclick-title {
                color: #ffff;
                font-family: $fontFamily;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: bold;

                &.opzioni {
                    opacity: 0.5;
                    font-weight: lighter;
                }
            }

            .mouseclick-arrow-icon {
                cursor: pointer;
                position: absolute;
                right: 20px;

                svg {
                    width: 40px;
                    height: 40px;
                }

                &.arrow-forward {
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

    .shelf-button {
        border: 0;
        background: transparent;
    }

    .shelf {
        position: absolute;
        margin: 0 12px;
        left: 0;
        right: 0;
        height: 20px;
        margin-bottom: 5px;
        align-self: flex-end;
        background: linear-gradient( 180deg, rgba(177, 177, 177, 1) 0%, rgba(177, 177, 177, 1) 7%, rgba(238, 238, 238, 1) 41%, rgba(242, 242, 242, 1) 43%, rgba(242, 242, 242, 1) 99%, rgba(175, 175, 175, 1) 100% );
    }

    #questionEditor {
        @include quill;
    }

    #addRowButton {
        border-radius: 40px;
        padding: 8px;
        text-transform: uppercase;
        font-size: 14px;
        background: $colorGrey;
        outline: none;
        border: 0;
        font-weight: bold;
        color: $colorWhite;
        margin-top: 20px;

        &:hover {
            background: #1a1a1a;
            border-radius: 40px;
            padding: 8px;
            font-size: 14px;
            outline: none;
            border: 0;
            font-weight: bold;
            color: $colorWhite;
        }
    }
}

#invito {
    width: 260px;
    height: 260px;
    background: url("../assets/images/conclusions/invito_off.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-color: transparent;

    &:hover {
        background: url("../assets/images/conclusions/invito_on.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom-color: transparent;
    }
}

.conclusion-margin {
    margin-left: 20px;
    margin-right: 40px;
}

.conclusion-title {
    width: 260px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.conclusion-text {
    width: 260px;
    font-size: 16px;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
}



#integra {
    width: 260px;
    height: 260px;
    background: url("../assets/images/conclusions/integra_off.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-color: transparent;

    &:hover {
        background: url("../assets/images/conclusions/integra_on.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom-color: transparent;
    }
}



#sceltalink {
    width: 260px;
    height: 260px;
    background: url("../assets/images/conclusions/sceltalink_off.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
        width: 260px;
        height: 260px;
        background: url("../assets/images/conclusions/sceltalink_on.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.conclusion-section {
    .video-table-scroll {
        height: 30rem;
        overflow-x: scroll;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 1rem !important;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px !important;
            box-shadow: 0 0 6px #6666664d !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #9a9999 !important;
            outline: 1px solid #fcfcfc !important;
            border-radius: 10px !important;
        }
        // &:hover {
        //     background-color: #b2b2b2;
        // }
    }
    .input-group {
        border: none;
        border-radius: 40px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        background: #fafafa;
        height: 35px;
    }

    .input-url {
        border: 0;
        border-radius: 40px;
        outline: none;
        background: #fafafa;
        height: 30px;

        &:focus {
            background: #fafafa;
        }
    }

    .icon-redirect {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 40px;
        background: #5f7884;
        color: $colorWhite;
        z-index: 1;
    }

    .test {
        background: lightgrey;
        border-radius: 10px;
        width: 100px;
        height: 50px;
    }

    .button-save {
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 14px;
        background: $colorGrey;
        outline: none;
        border: 0;
        font-weight: bold;
        color: $colorWhite;

        svg {
            width: 25px;
            height: 20px;
        }

        &:hover:not([disabled]) {
            background: $colorBlack;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.continua {
            background: #008ed6;

            &:hover,
            &:active,
            &:focus {
                background-color: $colorBlack;
            }
        }

        &.bozza {
            background-color: #f0b24f;

            &:hover {
                background: $colorBlack;
            }
        }

        &.launch {
            border: 0;
            background: transparent;
        }
    }

    .title {
        font-family: $fontFamily;
        font-size: 38px;
        color: $colorBlack;
    }

    .subtitle {
        font-family: $fontFamily;
        font-size: 16px;
        color: $colorBlack;
        text-transform: uppercase;
        font-weight: bold;
    }

    .statistics {
        font-family: $fontFamily;
        font-size: 30px;
        color: $colorBlack;
        text-transform: uppercase;
        font-weight: bold;
    }

    .table-title {
        background-color: #6dbec6;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        border-radius: 8px;
    }

    .shelf-table-scrollbar {
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .shelf-table-scrollbar::-webkit-scrollbar {
        width: .5rem;
    }

    .shelf-table-scrollbar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #bebebe4d;
    }

    .shelf-table-scrollbar::-webkit-scrollbar-thumb {
        background-color: #a1a1a14d;
        outline: 1px solid rgb(252, 252, 252);
        border-radius: 10px;
    }

    .form-group {
        @include form-group;
    }

    span {
        font-size: 12px;
        color: #1a1a1a;
    }

    #email {
        border-bottom: 0;
    }

    svg#createLink {
        width: 120px;
        height: 120px;

        &:hover {
            .st0 {
                fill: #000;
            }
        }
    }

    svg#launch {
        width: 130px;
        height: 130px;

        &:hover {
            .st0 {
                fill: #000;
            }
        }
    }

    svg#copyLink {
        width: 70px;
        height: 70px;

        &:hover {
            rect {
                fill: #000;
            }

            path {
                stroke: #fff;
            }
        }
    }

    .filter-label {
        display: flex;
    }

    .text-filter.form-control,
    .date-filter-input.form-control,
    .number-filter-input.form-control,
    .number-filter-comparator.form-control,
    .date-filter-comparator.form-control {
        font-size: 11px;
        padding: 0 0.2rem;
        line-height: 20px;
        height: 30px !important;
        margin-top: 5px;
    }
}

.launch-section {
    .button-save.launch {
        background-color: transparent !important;
        border-radius: 0;
    }

    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus {
        box-shadow: none;
    }
}

.calibrazione-section {
    background-color: #fff;
    position: absolute !important;
    left: 0;
    z-index: 100;
    top: 0;
    bottom: 0;

    .logo-section {
        height: 70px !important;
        width: 260px !important;

        img {
            margin-top: 17px !important;
            margin-left: 29px;
        }
    }

    .title {
        font-family: $fontFamily;
        font-size: 35px;
        color: $colorBlack;
        text-transform: uppercase;
        font-weight: normal;
    }

    .description {
        font-family: $fontFamily;
        font-size: 24px;
        color: $colorBlack;
        font-weight: normal;

        &.instruction {
            font-size: 20px;
        }
    }

    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
        box-shadow: none;
    }

    .button {
        height: 100px;
        border-radius: 20px;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: bold;
        box-shadow: none;
        outline: 0;
        border: 0;
        background-color: transparent;

        &:focus,
        &:active {
            outline: 0;
            border: 0;
            box-shadow: none;
        }

        &.cancel {
            background-color: #e6786c;
        }

        &.submit {
            background-color: #a0cd6c;
        }

        &:hover {
            background-color: #5f7884;
        }
    }

    .position {
        position: absolute;
    }

    .screen {
        height: 80vh;
    }

    .canvasStream {
        width: 640px;
        height: 480px;
    }
}

#seconds::-webkit-outer-spin-button,
#seconds::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    outline: none;
}

.video-section {
    // @include listify;
    .scroll {
        max-height: 500px;
        overflow-y: scroll !important;
        overflow-x: scroll !important;
    }

    .scroll::-webkit-scrollbar {
        width: 1rem;
    }

    .scroll::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: 0 0 6px #6666664d;
    }

    .scroll::-webkit-scrollbar-thumb {
        background-color: #9a9999;
        outline: 1px solid #fcfcfc;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #c3c1c1 !important;
        outline: 1px solid #fcfcfc !important;
        border-radius: 10px !important;
    }


    .listify {
        height: 50px !important;
        padding-left: 10px !important;
    }

    ul.listify > li::before {
        height: calc(1em + 1rem);
    }

    .title {
        font-family: "Open Sans", sans-serif;
        font-size: 38px;
        color: #1a1a1a;
        font-weight: normal;
        letter-spacing: 1px;
    }

    .text-field {
        font-family: $fontFamily;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .button,
    .button:focus,
    .button:hover,
    .button:active {
        padding: 0;
        box-shadow: none !important;
        background-color: transparent !important;
        height: 50px;
        border: 0 !important;

        #Path_3509 {
            fill: #5f7884;
        }
        //BUTTON CLICK
        &.active {
            #Path_3571-2 {
                fill: #008ed6;
            }

            #Path_3515,
            #Path_3516,
            #Path_3517,
            #Path_3518,
            #Path_3519,
            #Path_3520,
            #Path_3521,
            #Path_3522,
            #Path_3523,
            #Rectangle_340,
            #Path_3575 {
                stroke: #fff;
            }

            #base-2,
            #base-2_1_ {
                fill: #5f7884;
            }

            #Ellipse_115,
            #Ellipse_116,
            #Ellipse_117,
            #Ellipse_118,
            #Ellipse_119,
            #Ellipse_120,
            #Ellipse_121,
            #Subtraction_9,
            #Icon_awesome-play,
            .st2,
            .st3 {
                fill: #fff;
            }
        }
    }

    .video-player {
        position: relative;
    }

    .video-container {
        position: relative;
    }

    .canvas {
        position: absolute;
        top: 0;
    }

    .input-time {
        color: #000;
        border-radius: 10px;
        background: #eee !important;
        border: 0;
        height: 30px;
    }

    .shape-delete {
        border: 0;
        background: transparent;
    }

    .deleteAOI {
        background: url("../assets/images/analisi/aoi_elimina_off.svg");
        width: 16px;
        height: 20px;
        margin-left: 6px;

        svg {
            width: 16px;
            height: 20px;
        }

        &:hover {
            background: url("../assets/images/analisi/aoi_elimina_on.svg");
        }
    }

    .duplicateAOI {
        background: url("../assets/images/analisi/aoi_duplica_off.svg");
        width: 18px;
        height: 20px;

        svg {
            width: 18px;
            height: 20px;
        }

        &:hover {
            background: url("../assets/images/analisi/aoi_duplica_on.svg");
        }
    }

    .shape-color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .shape-name {
        border-radius: 0;
        border: 0;
    }

    .shape-info {
        border-bottom: 1px solid #ccc;
    }

    .controls {
        display: flex;
        grid-template-columns: auto auto 1fr;
        grid-gap: 10px;
        background: #eee;
        padding: 10px;
        width: 640px;

        .play {
            font-size: 1em;
            padding-top: 4px;
            padding-right: 0px;
            padding: 0px;
            border: none;
            box-shadow: none;
            background: transparent;
        }

        .mute {
            font-size: 1.3em;
            padding-top: 4px;
            padding-left: 0px;
            padding: 0px;
            border: none;
            box-shadow: none;
            background: transparent;
        }

        .time-data {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 5px;
            margin-top: 2px;
        }

        input[type="range"] {
            -webkit-appearance: none;
            background: transparent;
            overflow: hidden;
            cursor: pointer;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 6px;
            background: #ccc;
            border: none;
        }

        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            position: relative;
            height: 15px;
            width: 15px;
            margin-top: -4px;
            background: #333;
            border-radius: 50%;
        }
    }
}

.video-details {
    width: 350px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    .text-field {
        font-family: $fontFamily;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .form-control {
        font-size: 16px;
        text-transform: lowercase;
        text-align: left;
    }

    .compact-picker.color-picker {
        div:nth-child(1) {
            span > div > div {
                background: transparent !important;
            }
        }

        .flexbox-fix {
            div:nth-child(1) {
                left: 0px !important;
            }

            input {
                padding: 0 0 0 12px !important;
            }
        }
    }
}

.sticky-headers {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.video-table {
    height: 30rem;
    overflow-x: auto;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 1rem !important;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px !important;
        box-shadow: 0 0 6px #6666664d !important;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9a9999 !important;
        outline: 1px solid #fcfcfc !important;
        border-radius: 10px !important; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #c3c1c1 !important;
        outline: 1px solid #fcfcfc !important;
        border-radius: 10px !important;
    }
    
    // &:hover {
    // background-color: #b2b2b2;
    // }

}

.top-5-table {
    // height: 30rem;
    // overflow-x: auto;
    // overflow-y: auto;

    &::-webkit-scrollbar {
        width: 1rem !important;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px !important;
        box-shadow: 0 0 6px #6666664d !important;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9a9999 !important;
        outline: 1px solid #fcfcfc !important;
        border-radius: 10px !important; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #c3c1c1 !important;
        outline: 1px solid #fcfcfc !important;
        border-radius: 10px !important;
    }
    
    // &:hover {
    // background-color: #b2b2b2;
    // }

}

.upload-video {
    border-radius: 10px;
    box-shadow: 1px 5px 10px 0.3px rgba(0, 0, 0, 0.4);
    padding: 20px;

    .formati {
        color: #888888;
        font-size: 12px;
    }

    .video-label {
        font-weight: normal;
        text-transform: uppercase;
    }

    .button-save.continua {
        height: 40px;
    }

    .preview {
        display: none;
        border: 1px solid #ccc;
    }
}

.image-test-section {
    background-color: black;
    position: absolute !important;
    left: 0;
    z-index: 100;
    top: 0;
    bottom: 0;
    margin: 0;
    padding: 0;

    .img-test {
        width: 100%;
        height: 100%;
    }

    .position {
        position: absolute;
    }
}

.fake-shelf-test-section {
    position: absolute !important;
    left: 0;
    z-index: 100;
    top: 0;
    bottom: 0;
    margin: 0;
    padding: 0;

    .fake-shelf-test {
        width: 100%;
        height: 100%;
    }

    .position {
        position: absolute;
    }
}

.shelf-upload{
    .canvas{
        position: absolute;
        inset: 0;
        width: 100%;
        height: auto;
    } 
    .carica-backgrnd{
        width: 125px;
        height: 125px;
    }
    .react-transform-component{
        display: flex;
        flex-grow: 1;
        @include form-group;
    }
    .react-transform-element{ 
        flex-grow: 1;
        justify-content: center;
    }
    .upload-shelf-flex-center{
        display: flex;
        justify-content: center;
        flex-grow: 1;
    }
    .upload-shelf-container{
        position: relative;
        width: 100%;
        max-width: 1920px;
    }


}

.modal-export {
    .subtitle {
        font-family: $fontFamily;
        font-size: 14px;
        color: $colorBlack;
        text-transform: uppercase;
        font-weight: bold;
    }

    .label {
        text-transform: uppercase;
    }
}

.modal-preview {
    display: flex;
    justify-content: center;

    .modal-content {
        width: 100vw;
    }

    .modal-colored-header {
        background-color: #5f7884;
    }

    .shelf-container {
        .label-price {
            position: absolute;
            font-size: 8px;
            font-weight: bold;
            bottom: -12px;
            z-index: 10;
            margin-bottom: 0;
            background-color: #fff;
            padding: 0 15px;
        }

        .shelfImages {
            position: relative;
            width: 100%;
            margin: 0 25px;

            &:before {
                content: "";
                background: url("../assets/images/erogazione/finale_shelf_sinistra.svg");
                background-size: cover;
                background-repeat: no-repeat;
                width: 20px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -20px;
                z-index: 10;
            }

            &:after {
                content: "";
                background: url("../assets/images/erogazione/finale_shelf_destra.svg");
                background-repeat: no-repeat;
                background-size: cover;
                width: 20px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -20px;
                z-index: 10;
            }
        }

        .shelfRow {
            background: url("../assets/images/erogazione/ripiano_shelf.svg");
            background-size: auto 100%;
        }

        .imagesByRow:last-child {
            .shelfBase {
                background: url("../assets/images/erogazione/base_shelf.svg");
                background-size: cover;
                width: 100%;
                height: 50px;
            }
        }
    }
}

.circle-wrap {
    margin: 150px auto;
    width: 150px;
    height: 150px;
    background: #ffffff;
    border-radius: 50%;
    border: 1px solid #5f7884;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
}

.circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .inside-circle {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background: #ffffff; //#d2eaf1; //celeste chiaro
    line-height: 120px;
    text-align: center;
    margin-top: 14px;
    margin-left: 14px;
    color: #5f7884; // blu
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 2em;
}

/* color animation */
/* 3rd progress bar */
.mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: #008ed6; // celeste scuro
}

.mask.full,
.circle .fill {
    animation: fill ease-in-out 50s;
    transform: rotate(180deg);
}

@keyframes fill {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.rotate {
    animation: rotation 5s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@media screen and (max-width: 576px) {
    
    .left-side-section{
        width: 200px;
    }

    .carousel-container{
        overflow-x: scroll;
    }

    .new-button {
        left: 10px;
        top: 100px;
        width: 100px !important;

        .button-crea {
            margin-left: 20px;

            svg {
                width: 60px;
                height: 60px;
            }
        }
    }

    .dashboard-section {
        .filter-button {
            .name {
                margin-left: 30px;
            }

            svg {
                height: 30px;
            }

            .ordine {
                position: absolute;
                right: 1%;

                &.date {
                    text-align: center;
                }
            }
        }

        .card {
            .icon {
                width: 40px;
                height: 40px;
                padding: 0px;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 0px;
                }
            }

            .actions {
                p {
                    font-size: 9px;
                }
            }
        }

        .pagination {
            .page-item {
                font-size: 10px;
            }

            button.page-link {
                padding: 7px;
                min-width: 20px !important;
            }
        }
    }

    .modal-product {
        .amount-button {
            svg {
                width: 50px;
                height: 50px;
            }
        }

        .image {
            width: 60px;
            height: 60px;

            .no-image {
                width: 60px;
                height: 60px;
            }
        }
    }

    .partire-section.erogazione {
        .screen {
            height: auto;
        }
    }

    .calibrazione-section {
        .title {
            font-size: 25px;
        }

        .description {
            font-size: 16px;
        }

        .screen {
            height: 68vh;

            .title {
                font-size: 18px;
            }

            .instructions {
                width: 50px;
                height: 50px;
            }

            .description {
                font-size: 12px;
            }
        }

        .button {
            height: 50px;
            font-size: 16px;
        }

        .canvasStream {
            width: 256px;
            height: 192px;
        }

        .calibrateIcons {
            width: 40px;
            height: 40px;
        }

        .icons {
            width: 100px;
            height: 100px;
        }

        .buttons {
            width: 30px;
            height: 30px;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .new-button {
        left: 10px;
        top: 100px;

        .button-crea {
            margin-left: 20px;

            svg {
                width: 70px;
                height: 70px;
            }
        }
    }

    .dashboard-section {
        .filter-button {
            svg {
                height: 30px;
            }

            .ordine {
                position: absolute;
                right: 8%;

                &.date {
                    text-align: center;
                }
            }
        }

        .card {
            .study {
                margin-left: -12px;
                margin-top: -20px;

                svg {
                    width: 50px;
                }
            }

            .list {
                // margin-left: -13px;
                // margin-top: -20px;

                svg {
                    width: 50px;
                }
            }

            .icon {
                svg {
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    svg circle {
                        fill: $colorBlack;
                    }
                }
            }

            .title {
                font-size: 16px;
            }

            .description {
                height: 30px;
            }

            .actions {
                background-color: #e3e3e3;
                border-radius: 0 0 12px 12px;

                p {
                    font-size: 10px;
                }

                &.list {
                    background-color: transparent;
                }
            }
        }
    }

    .quill .ql-toolbar .ql-snow {
        background-color: #008ED6;
    }
    
    .studies-section {
        .title {
            font-size: 24px;
        }

        .flag-icon {
            width: 20px;
            height: 20px;
        }

        .form-group {
            label {
                font-size: 12px;
            }

            .btn {
                font-size: 9px;
            }
        }
    }

    .stimoli-section {
        .opzioni-section {
            .random {
                /*position: absolute !important;
        left: 120px;
        top: 120px;*/
            }

            .p2 {
                margin-left: 20px;
            }

            .p1 {
                margin-left: 29px;
            }

            .button-save {
                font-size: 10px;
            }

            .off-backgrnd,
            .dis-backgrnd {
                height: 40px;
            }

            .carica-backgrnd {
                height: 100px;
            }
        }
        /*
        #surveyMessage {
            @include quill;
        }
        */
    }

    .modal-product {
        .amount-button {
            svg {
                width: 30px;
                height: 30px;
            }
        }

        .image {
            width: 60px;
            height: 60px;

            .no-image {
                width: 60px;
                height: 60px;
            }
        }

        .product {
            img {
                width: auto;
                height: 130px;
                border-radius: 4px;
            }
        }
    }

    .modal-dialog.modal-cart {
        .modal-content {
            width: 300px;
        }
    }

    .partire-section {
        .video-display {
            position: relative;
            height: 200px;
        }
    }

    .calibrazione-section {
        .title {
            font-size: 28px;
        }

        .description {
            font-size: 20px;

            &.instruction {
                font-size: 14px;
            }
        }

        .button {
            height: 60px;
            font-size: 16px;
        }

        .instructions {
            width: 60px;
            height: 60px;
        }

        .calibrateIcons {
            width: 30px;
            height: 30px;
        }

        .canvasStream {
            width: 320px;
            height: 240px;
        }

        .buttons {
            width: 30px;
            height: 30px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .new-button {
        left: 10px;
        top: 100px;

        .button-crea {
            svg {
                width: 80px;
                height: 80px;
            }
        }
    }

    .dashboard-section {
        .filter-button {
            .name,
            .ordine {
                font-size: 9px;
            }

            &.grid {
                height: 30px;

                svg {
                    height: 15px;
                }
            }
        }

        .card {
            .icon {
                margin: 0px !important;

                svg {
                    width: 40px;
                    height: 40px;
                }

                .actions {
                    p {
                        font-size: 8px;
                    }
                }
            }
        }
    }

    .calibrazione-section {
        .canvasStream {
            width: 512px;
            height: 384px;
        }

        .buttons {
            width: 30px;
            height: 30px;
        }

        .icons {
            width: 100px;
            height: 100px;
        }

        .button {
            height: 60px;
            font-size: 16px;
        }

        .screen {
            .title {
                font-size: 25px;
            }

            .instructions {
                width: 50px;
                height: 50px;
            }

            .description {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 1028px) {
    .new-button{
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
    }
}

@media screen and (min-width: 768px) {
    .new-button{
        position: absolute !important;
        top: 0;
        left: 60px;
        right: 0;
    }
    .logo-section {
        height: 70px !important;
        width: 260px !important;

        // img {
        //     margin-top: 17px !important;
        //     margin-left: 29px;
        // }
    }
}

@media screen and (min-width: 1029px) {
    .new-button{
        position: absolute !important;
        left: 190px;
        top: 100px;
    }

}

@media screen and (max-width: 1029px) {
    .input-searchbar {
        margin-top: 75px;
    }
}

@media screen and (min-width: 993px) and (max-width: 1280px) {
    body[data-leftbar-compact-mode="condensed"] .new-button {
        .button-crea {
            margin-left: -190px;
        }
    }

    .dashboard-section {
        .filter-button {
            .name {
                font-size: 10px;
                margin-left: 30px;
            }

            .ordine {
                font-size: 10px;
                margin-right: 30px;
            }

            &.grid {
                width: 40px;
                margin: 0 3px;
                height: 25px;
            }
        }
    }

    .calibrazione-section {
        .canvasStream {
            width: 640px;
            height: 480px;
        }

        .buttons {
            width: 50px;
            height: 50px;
        }
    }
}

@media (min-width: 1280px) {
    .close--menu-button {
        visibility: visible;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
    .stimoli-section {
        .opzioni-section {
            .random {
                /*position: absolute !important;
        left: 230px;
        top: 155px;*/
            }
        }
    }
    .left-side-section-open {
        transition: .2s;
        left: 0%;
    }

    .left-side-section-close {
        transition: .4s;
        left: -100%;
    }
}

@media screen and (min-width: 1029px) {

    .left-side-section-open {
        transition: .2s;
        left: 0%;
    }

    .left-side-section-close {
        transition: .4s;
        left: 0%;
    }
    .open--menu-button{
        visibility: hidden;
    }
    // .close--menu-button{
    //     visibility: collapse;
    // }
}


@media screen and (min-width: 1441px){
 .stimoli-section {
        .carica-button{
            width: 180px;
            height: 180px;
            padding: 10px 20px;
            gap:10px;
            font-size: 18px;
            line-height: 24px;
        }

     .opzioni-section {
        .add-background {
            width:220px;
            height: 220px;
           
            .add-image {
                padding-bottom: 7px;
                align-items: center;
                width: 80px;
                height: 80px;
                font-size: 40px;
            }
        }       
    }
    }

 .on-button {
    &.img-info {
        border: 0;
        width: 200px !important;
        height: 200px !important;
        padding: 10px;
    }   
}

.shelf-upload{
    .carica-backgrnd{
        width: 220px;
        height: 200px;
    }
}


}


@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .dashboard-section {
        .filter-button {
            svg {
                height: 40px;
            }

            &.grid {
                svg {
                    height: 30px;
                }
            }

            .ordine {
                width: 75%;
                font-size: 14px;
            }
        }

        .card {
            .list {
                // margin-left: -13px;
                // margin-top: -32px;

                svg {
                    width: 50px;
                }
            }

            .icon {
                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .stimoli-section {
        .opzioni-section {
            .random {
                /*position: absolute !important;
        left: 285px;
        top: 155px;*/
            }

            .p2 {
                margin-left: 90px;
            }

            .p1 {
                margin-left: 100px;
            }
        }
    }
}

@media screen and (min-width: 2560px) {
    .stimoli-section {
        .opzioni-section {
            .random {
                /*position: absolute !important;
        left: 420px;
        top: 155px;*/
            }

            .p2 {
                margin-left: 160px;
            }

            .p1 {
                margin-left: 167px;
            }
        }
    }
}

@media screen and (min-width: 1028px) and (max-width: 1212px) {
    .dashboard-section {
            .card {
                .icon {
                    margin: 0px !important;
    
                    svg {
                        width: 30px;
                        height: 30px;
                    }
    
                    .actions {
                        p {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
}
@media screen and (min-width: 1367px) and (max-width: 1729px) {
    .dashboard-section {
            .card {
                .icon {
                    margin: 0px !important;
    
                    svg {
                        width: 30px;
                        height: 30px;
                    }
    
                    .actions {
                        p {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
}