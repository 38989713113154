.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row {
  border: 1px dotted grey;
}

.box .row.header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

/*General rules */
.sidebar-enable {
  background-color: #fff !important;
}

.left-side-menu {
  background: #e3e3e3 !important;
  position: absolute;
  box-shadow: none;
}
body[data-leftbar-theme="dark"] .left-side-menu .logo {
  background: #e3e3e3 !important;
  position: absolute;
}
body[data-leftbar-theme="dark"] .side-nav .mm-active > a {
  color: #000 !important;
}
.left-side-menu .logo-lg img,
.account-pages .card img {
  height: 40px;
}
.content-page {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 0px 10px #a2a2a2;
  background-color: #fff;
}
.navbar-custom {
  border-top-left-radius: 20px;
  box-shadow: none;
  z-index: 10;
  padding: 0;
  position: absolute;
}
body.authentication-bg {
  background-image: none;
  background-color: #c2d9e8;
}
.content {
  margin-bottom: 5rem;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.footer {
  padding: 10px;
}

.username {
  color: #888888;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.navUser {
  background-color: transparent;
  border: 0;
  padding: 15px 20px 15px 0px;
  position: relative;
  min-height: 70px;
}

@media screen and (max-width: 1028px){
  .left-side-menu{
    display: none !important;
    visibility: hidden !important;
  }
  .content-page{
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px !important;
  }
}

/* Mobile-M Mobile-L */
@media screen and (max-width: 767px) {
  body {
      overflow-y: scroll;
    }
  .navbar-custom {
    left: 80px;
    padding: 0;
  }

  .left-side-menu,
  .logo {
    width: 80px;
  }

  span.logo-lg img {
    height: 16px !important;
  }

  li.side-nav-title.side-nav-item {
    font-size: 10px;
  }

  a.side-nav-link-ref.side-sub-nav-link,
  a.has-arrow.side-sub-nav-link.side-nav-link {
    padding: 5px;
    font-size: 12px;
  }

  .side-nav .side-nav-link {
    padding: 10px;
  }

  .side-nav-forth-level li a,
  .side-nav-second-level li a,
  .side-nav-third-level li a {
    padding: 8px 20px;
  }

  .metismenu .has-arrow:after {
    right: 0.3em;
  }
  li.side-nav-title.side-nav-item {
    padding: 0 10px;
  }
}

#webgazerVideoContainer {
  z-index: 100;
}

ul.all-height.listify {
  overflow-y: hidden !important;
  height: fit-content !important;
}

.ag-theme-balham {
  height: 30vh;
}

.table-responsive > .table-bordered {
  border: 1px solid #eee;
  table-layout: fixed;
}

.thead {
  background-color: #f2f2f2;
}

.react-bootstrap-table th.sortable {
  font-size: 12px;
}

.table-centered th, .table-centered td {
  padding: 5px;
}

.table-bordered td {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-right: 0;
  border-left: 0;
}